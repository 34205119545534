#projects {
  padding: 0 20px;
  background-color: var(--background-color);
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers grid items */
  align-items: center;
  margin: auto;
  box-sizing: border-box; /* Includes padding in the width calculation */
}

.projects-container {
  display: grid;
  gap: 20px;
  padding: 20px;
}

.project-card {
  padding: 10px;
  box-sizing: border-box;
  background: var(--card-color);
  border-radius: 8px;
  margin: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 400px;
  max-height: 500px;
  transition: all 0.3s ease;
  cursor: pointer;
  flex: 1 1 calc(45% - 40px);
}
.project-card:hover {
  transform: translateY(-10px);
  background: var(--card-hover-color);
}

.project-card img {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.project-card h3 {
  padding: 15px;
  margin: 0;
  margin-top: 0;

}

.project-card p {
  padding: 0 15px 15px;
  margin: 0;
}

.project-card.expanded {
  /* grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 2fr; */
  /* color: gray; */
  gap: 20px;
}

.project-card.expanded p {
  margin-top: 10px;
  color: rgb(87, 87, 87);
}

.project-card.expanded img {
  height: 100%;
}

.project-card.expanded h3 {
  grid-column: 2;
  padding-bottom: 0;
}

.project-card.expanded p {
  grid-column: 2;
}


@media (min-width: 901px) and (max-width: 2000px) {
  .projects-container{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 2001px) {
  .projects-container{
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 900px) {
  .project-card {
    max-width: 100%;
    flex: 1 1 100%;
}
  .project-card.expanded {
    grid-template-columns: 1fr;
  }

  .project-card.expanded img {
    height: 200px;
  }

  .project-card.expanded h3,
  .project-card.expanded p {
    grid-column: 1;
  }
}
