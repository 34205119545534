/*! Colour palette */
:root {
    --primary-color: #3f978d; /*#003F84;  */
    --secondary-color: #3f978d;  /*#64ABDE;   Charite Neurology color = rgb(28	152	116	); */ 
    --background-color: #f0f2f5;
    --card-color: white;
    --card-hover-color: #f9f9f9;
    --section-color: #f9f9f9;
    /* --team-background-color: rgb(15, 15, 15); black theme
    --team-card-color: rgb(54, 54, 54);
    --section-color: #f9f9f9;
    --team-text-color: white; */
    --team-background-color: #f0f2f5;
    --team-card-color: white;
    --team-text-color: black;
    --team-section-color: #f9f9f9;
}
.more-text {
    color: grey;
    background-color: transparent;
   } 

body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    color: #333;
    width: 100%;
}

header {
    /*background: #3f978d; */
    background: var(--primary-color);
    color: white;
    padding: 0;
    text-align: center;
    z-index: 1000;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    max-width: 1200px;
    margin: auto;
  }
  
  
  
  
  
  header h1 {
      margin: 0;
      font-size: 2.5em;
    }
    
    .header-logo {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        object-fit: cover;
    }


nav {
    display: flex;
    top:0;
    cursor: pointer;
    justify-content: center;
    background-color: black;
    border-bottom: 2px solid black;
    padding: 15px;
    position: sticky;
    z-index: 1000; /* Lower than header */
}

nav a {
    color: white;
    margin: 0 15px;
    text-decoration: none;
    font-size: 1.1em;
    border-bottom: 2px solid black;

}

nav a:hover {
    border-bottom: 2px solid var(--secondary-color);
}

section {
    padding: 50px 20px;
    /* max-width: 1200px; */
    margin: auto;
    text-align: center;
}

.section-title {
    font-size: 2em;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--secondary-color);
    display: inline-block;
    padding-bottom: 10px;
    align-self: center;
}

.home-image {
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 20px auto;
}

.logo {
    height: 200px; /* Increased height */
    margin: 0 20px;
}

.year-section h3 {
    margin: 0 0 20px 0;
    font-size: 1.5em;
    text-align: left;
}

footer {
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
    box-sizing: border-box; /* Includes padding in the width calculation */
}

footer a {
    color: white;
}



@media (max-width: 768px) { /* Adjust styles for smartphones */
    header h1 {
        font-size: 2em;
    }
    nav a {
        font-size: 1em;
        margin: 0 10px;
    }
    .logo {
        height: 70px; /* Adjusted height for smaller screens */
        margin: 10px 0;
    }
    .header-content {
        flex-direction: column;
        align-items: center;
    }
    .header-logo {
        width: 50px;
        height: 50px;
        margin-top: 10px;
    }
}

@media (max-width: 1536px) {
    header h1 {
        font-size: 1.5em; /* Adjusted font size for smaller screens */
    }
    .header-content {
        padding: 10px; /* Reduced padding */
    }
    .header-logo {
        width: 50px; /* Reduced logo size */
        height: 50px;
    }
    .header-content {
        flex-direction: column;
        align-items: center;
    }
}
