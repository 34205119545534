#team {
  background-color: var(--team-background-color);
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; /* Includes padding in the width calculation */
}


/* .team-title {
  color: white;
  
} */

#team .section-title{
  color: var(--team-text-color)
}
#team p{
  color: var(--team-text-color);
}
#team h3{
  color: var(--team-text-color);
}

.team-section-container{
  display: flex;
  
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  
   /* Includes padding in the width calculation */
}

.team-container-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box; /* Includes padding in the width calculation */
}
.team-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
  gap: 20px;
  padding: 20px;
  }
 
 
  .team-card {
    background: var(--team-card-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 50px;
    border-radius: 8px;
    transition: transform 0.3s ease;
    box-sizing: border-box;
    margin: 10px;
	max-width: 400px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .team-card:hover {
    transform: translateY(-10px);
    background: #f0f0f0;
    cursor:pointer;
  }
  
  .team-card-inner {
    height: auto;
    display: flex;
    flex-direction: column;
  }
  
  .team-card img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto 15px;
  }
  
  .team-card h3 {
    grid-column: 2;
    padding: 15px;
    margin: 0;
  }
  .team-card p {
    padding: 0 15px 15px;
    margin: 0;
  }
  .team-card role  {
    font-style: italic;
    margin: 0 0 10px;
    padding: 0 10px;
  }
  
  .team-member-desc {
    flex-grow: 1;
    overflow: auto;
    padding: 0 10px 10px;
    margin: 0;
  }
  

  
  /* .team-card-inner.expanded{
    margin-top: 10px;
    color: rgba(255, 251, 251, 0.978);
  }
  
  .team-card.expanded img{
  }
  
  .team-card.expanded h3{
    grid-column: 2;
    padding: 15px;
  }
  
  .team-card.expanded p {
    grid-column: 2;
  }
  .team-member-role.expanded{
    grid-column: 2;
  }
  
  .team-member-desc.expanded{
    flex-grow: 1;
    overflow: auto;
    padding: 0 10px 10px;
    margin: 0;
  } */

  /* @media (max-width: 768px) {
    .team-container {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  } */
