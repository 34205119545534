*, *::before, *::after {
  box-sizing: border-box;
}
#publications {
    padding: 40px;
    background-color: #f9f9f9;
  }
  
  .section-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2em;
  }
  
  .year-section {
    margin-bottom: 20px;
  }
  
  .year-section h3 {
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  
  .year-container {
    flex-direction: column;
    grid-template-columns: 1fr; /* Single column layout */
    justify-content: center; /* Center the grid items */
    gap: 15px;
  }
  
  .publication-card {
    background: white;
    text-align: center;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    max-width: 1600px; /* Increase card width */
    width: 100%;
    margin: 20px auto;
  }

  .publication-card:hover {
    transform: translateY(-10px);
    background: var(--card-hover-color);
    cursor: pointer;
}
  
  .publication-card h4 {
    margin: 0 0 10px 0;
    font-size: 1.2em;
  }
  
  .publication-card p {
    margin: 5px 0;
  }
  
  @media (max-width: 768px) {
    .publication-card {
      padding: 10px; /* Reduce padding inside cards */
      margin: 15px auto; /* Slightly reduce margins */
    }
  }